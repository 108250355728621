@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.loadingContainer {
  background-color: #ffffff;
  display: flex;
  min-height: 70vh;
}
.loadingGif{
  margin: auto;
  margin-top: 40%;
}

.overlay {
  grid-area: 1 / 1;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.5);
  /* position: relative; */
}
.underlay {
  grid-area: 1 / 1;
}

.overlayContainer {
  display: grid;
}

.button {
  color: white;
  background-color: #32B651;
  border-radius: 8px;
  text-align: center;
  position: absolute;
  top: 15%;
  left: 36%;
  width: 24rem;
  height: 3rem;
  margin: -23px 0 0 -25px;
  font-size: 2rem;
  font-weight: bold;
}


@media (max-width: 800px) {

.button {
  left: 33%;
  max-width: 12rem;
  max-height: 4rem;
  font-size: 2.3rem;
  font-weight: bold;
}

  
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
